
import { defineComponent } from 'vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
// import EntityModal from "@/components/modals/forms/entity/EntityModal.vue";
import EntityModal from '@/layout/header/partials/association/EntityListDrawer.vue';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'all-entity-list',
  components: {
    EntityModal,
    Datatable,
    axios,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      load: false,
      lists: [],
      tableData: [],

      data: {},
      api_url: '',
      per_entity_type_id: '',
      id: this.$route.params.id,
      entityTypes: [],
      tranches: [] as any,
      optionsTranche: [] as any,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Logo',
          key: 'logo',
          sortable: true,
        },
        {
          name: 'Short Name',
          key: 'short_name',
          sortable: true,
        },
        {
          name: 'Full Name',
          key: 'name',
          sortable: true,
        },

        {
          name: 'Telephone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },

        {
          name: 'Website',
          key: 'website',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
      ],
      formData: {
        entity_type_id: '',
        tranche_id: [] as any,
      } as any,
      showGridView: true,
      showListView: false,
      componentKey: 0,
    };
  },
  async created() {
    this.load = true;
    await this.getData();
    await this.getTypes();
    await this.getTranche();
    // await this.filterEntity();
    this.emitter.on('entity-updated', async () => {
      await this.getData();
      this.componentKey += 1;
    });
    this.api_url = this.VUE_APP_API_URL;
    this.per_entity_type_id = VueCookieNext.getCookie('_seip_entity_type');
    if (this.per_entity_type_id) {
      this.load = false;
    }
  },
  methods: {
    async printPdf() {
      let formData = new FormData();
      let all = '1';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        this.formData.entity_type_id = VueCookieNext.getCookie('_entity_id');
        let all = '0';
      }

      formData.set('tranche_id', this.formData.tranche_id);
      formData.set('entity_type', this.formData.entity_type_id);
      formData.set('all', all);

      axios
        .post(`${this.VUE_APP_API_URL}/api/entity/entity-list_print`, formData)
        .then(response => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      let all = '1';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        this.formData.entity_type_id = VueCookieNext.getCookie('_entity_id');
        let all = '0';
      }

      formData.set('tranche_id', this.formData.tranche_id);
      formData.set('entity_type', this.formData.entity_type_id);
      formData.set('all', all);
      await ApiService.post('entity/entity-list_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.formData.tranche_id);
      formData.set('entity_type', this.formData.entity_type_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/entity/entity-list_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Entity List.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getData() {
      this.load = true;
      let entity_id = '';
      let all = 1;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
        all = 0;
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.lists = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getTypes() {
      await ApiService.get('configurations/entity_type/list')
        .then((response) => {
          this.entityTypes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.tranches.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async filterEntity() {
      this.load = true;
      if (this.id) {
        this.formData.entity_type_id = String(this.id);
        this.formData.tranche_id = '';
      }
      await ApiService.get(
        'entity/list?entity_type=' +
        this.formData.entity_type_id +
        '&tranche_id=' +
        this.formData.tranche_id +
        '&all=' +
        1
      ).then((response) => {
        console.log(response);
        this.lists = response.data.data;
        this.componentKey += 1;
        this.load = false;
      });
    },

    view(infos) {
      this.data = infos;
    },
    edit(data) {
      this.emitter.emit('entity-edit-modal-data', data);
    },
    gridView() {
      this.showGridView = true;
      this.showListView = false;
    },
    listView() {
      this.showListView = true;
      this.showGridView = false;
    },

    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('entity/delete/' + `${id}`)
            .then((response) => {
              if (response.data.status == 'error') {
                this.load = false;
                Swal.fire({
                  title: 'Error!',
                  html: response.data.message,
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Close',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                });
              } else {
                Swal.fire('Deleted!', response.data.data, 'success').then(() => {
                  this.emitter.emit('entity-updated');
                });
              }

            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
